import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  // Muestra un indicador de carga mientras verificamos el estado de autenticación
  if (loading) return <div>Cargando...</div>;

  // Redirige al inicio de sesión si no hay usuario
  return user ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
