import React, { useState } from "react";
import * as XLSX from "xlsx";
import { db } from "../firebaseConfig";
import Item from "../models/Item"; // Clase Item que ya creamos anteriormente
import { useAuth } from "../context/AuthContext";
import Header from "./Header";
import { setDoc, doc, getDoc, serverTimestamp, updateDoc, increment } from "firebase/firestore";

const Orders = () => {
  const { logout } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    setIsProcessing(true);
    setMessage("Procesando archivo...");
  
    try {
      // Leer archivo como workbook de Excel
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Tomar la primera hoja
      const sheet = workbook.Sheets[sheetName];
  
      // Convertir datos a formato JSON
      const rows = XLSX.utils.sheet_to_json(sheet);

      const orderRef = doc(db, "orders", rows[0]["Order No."]);
      const orderSnap = await getDoc(orderRef);
      if(orderSnap.exists()) {
        setMessage("La orden " + rows[0]["Order No."] + " ya se cargó");
      } else {
        rows.forEach(async (row) => {
          if(!row["Order No."]) return;
          const skuArray = row["Sku"].split("|");
          const sku = skuArray[0];
          
          const docRef = doc(db, "items", sku);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            await updateDoc(docRef, {
              stock: increment(parseInt(row["Shipped Qty"]))
            });
          } else {
              /* const outOfStock = parseInt(row["Out of Stock"]);
              const qty = parseInt(row["Qty"]); */
      
              // Validar condición: Out of Stock < Qty
              if (parseInt(row["Shipped Qty"]) <= 0) return;
      
              // Crear datos del documento usando la clase Item
              /* const itemData = new Item(
              sku,
              "",
              row["Product Name"],
              parseFloat(row["Price"].replace("MXN ", "")), // Convertir precio
              0,
              parseInt(row["Shipped Qty"])
              ); */
              
              // Preparar documento para agregar a Firestore
              /* const docRef = doc(db, "items", sku); // Corrección: usar doc() para definir el documento
              docRef.set(docRef, itemData.toFirestore()); */
              await setDoc(doc(db, "items", sku), {
                image: "",
                description: row["Product Name"],
                style: row["Sku"].replace(sku + "|", ""),
                originalPrice: parseFloat(row["Price"].replace("MXN ", "")),
                ourPrice: 0,
                stock: parseInt(row["Shipped Qty"])
              });
          }
        });

        await setDoc(doc(db, "orders", rows[0]["Order No."]), {
          timestamp: serverTimestamp()
        });
      }
  
      // Ejecutar batch
    //   await batch.commit();
      
    } catch (error) {
      console.error("Error procesando archivo:", error);
      setMessage("Ocurrió un error al procesar el archivo.");
    } finally {
        setMessage("Archivo procesado con éxito.");
        setIsProcessing(false);
    }
  };

  return (
    <div className="orders-container">
      <Header sectionName="Ordenes" onLogout={logout} />
      <h1>Ordenes</h1>
      <input
        type="file"
        accept=".xlsx, .xls, .csv"
        onChange={handleFileUpload}
        disabled={isProcessing}
      />
      {isProcessing && <p>Procesando archivo...</p>}
      {message && <p>{message}</p>}
    </div>
  );
};

export default Orders;
