import React from "react";
import "./Header.css";

const Header = ({ sectionName, onLogout }) => (
  <header className="header">
    <h1>{sectionName || "Brillare Admin"}</h1>
    <button onClick={onLogout} className="logout-button" aria-label="Cerrar sesión">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="logout-icon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a2 2 0 01-2 2H6a2 2 0 01-2-2V7a2 2 0 012-2h5a2 2 0 012 2v1"
        />
      </svg>
    </button>
  </header>
);

export default Header;
