import React, { useState, useEffect } from "react";
import "./AddEditItemModal.css";

const AddEditItemModal = ({ item, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    sku: "",
    image: "",
    description: "",
    style: "",
    originalPrice: 0,
    ourPrice: 0,
    stock: 0,
  });

  useEffect(() => {
    if (item) {
      // Si estamos editando un ítem, inicializamos el formulario con sus datos
      setFormData({
        sku: item.sku,
        image: item.image,
        description: item.description,
        style: item.style,
        originalPrice: item.originalPrice,
        ourPrice: item.ourPrice,
        stock: item.stock,
      });
    } else {
      // Si estamos creando un nuevo ítem, limpiamos el formulario
      setFormData({
        sku: "",
        image: "",
        description: "",
        style: "",
        originalPrice: 0,
        ourPrice: 0,
        stock: 0,
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "originalPrice" || name === "ourPrice" || name === "stock" ? parseFloat(value) || 0 : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validación básica
    if (!formData.sku || !formData.description) {
      alert("El SKU y la descripción son obligatorios.");
      return;
    }

    // Llamar a la función onSave con los datos del formulario
    onSave(formData);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{item ? "Editar Ítem" : "Agregar Nuevo Ítem"}</h2>
        <form onSubmit={handleSubmit}>
          {/* Campo SKU */}
          <div className="form-group">
            <label htmlFor="sku">SKU</label>
            <input
              type="text"
              id="sku"
              name="sku"
              value={formData.sku}
              onChange={handleChange}
              placeholder="ID único para el ítem"
              disabled={!!item} // Desactivado si estamos editando
            />
          </div>

          {/* Campo Imagen */}
          <div className="form-group">
            <label htmlFor="image">Imagen (URL)</label>
            <input
              type="text"
              id="image"
              name="image"
              value={formData.image}
              onChange={handleChange}
              placeholder="URL de la imagen del ítem"
            />
          </div>

          {/* Campo Descripción */}
          <div className="form-group">
            <label htmlFor="description">Descripción</label>
            <input
              type="text"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Descripción del ítem"
            />
          </div>

          {/* Campo Estilo */}
          <div className="form-group">
            <label htmlFor="style">Estilo</label>
            <input
              type="text"
              id="style"
              name="style"
              value={formData.style}
              onChange={handleChange}
              placeholder="Estilo del ítem"
            />
          </div>

          {/* Campo Precio Original */}
          <div className="form-group">
            <label htmlFor="originalPrice">Precio Original</label>
            <input
              type="number"
              id="originalPrice"
              name="originalPrice"
              value={formData.originalPrice}
              onChange={handleChange}
              placeholder="Precio original del ítem"
            />
          </div>

          {/* Campo Precio Nuestro */}
          <div className="form-group">
            <label htmlFor="ourPrice">Precio de Venta</label>
            <input
              type="number"
              id="ourPrice"
              name="ourPrice"
              value={formData.ourPrice}
              onChange={handleChange}
              placeholder="Precio de venta del ítem"
            />
          </div>

          {/* Campo Stock */}
          <div className="form-group">
            <label htmlFor="stock">Stock</label>
            <input
              type="number"
              id="stock"
              name="stock"
              value={formData.stock}
              onChange={handleChange}
              placeholder="Cantidad en stock"
            />
          </div>

          {/* Botones */}
          <div className="form-actions">
            <button type="submit">{item ? "Guardar Cambios" : "Agregar Ítem"}</button>
            <button type="button" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditItemModal;
