import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import AddSaleModal from "./AddSaleModal"; // Importamos el nuevo componente
import Header from "./Header";
import "./Sales.css";

const db = getFirestore();

const Sales = () => {
  const { logout } = useAuth();
  const [sales, setSales] = useState([]);
  const [newSale, setNewSale] = useState({
    customer: "",
    paymentMethod: "",
    products: [],
    total: 0,
  });
  const [sku, setSku] = useState("");
  const [itemResult, setItemResult] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // Fetch sales from Firestore
  useEffect(() => {
    const fetchSales = async () => {
      const salesCollection = collection(db, "sales");
      const salesSnapshot = await getDocs(salesCollection);
      const salesData = salesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSales(salesData);
    };

    fetchSales();
  }, []);

  // Search for item by SKU
  const handleSearchSKU = async () => {
    setMessage("");
    setItemResult(null);

    try {
      const itemDoc = await getDoc(doc(db, "items", sku));
      if (itemDoc.exists()) {
        setItemResult({ id: itemDoc.id, ...itemDoc.data() });
        setQuantity(1); // Reset quantity for new search
      } else {
        setMessage("Artículo no encontrado.");
      }
    } catch (error) {
      console.error("Error buscando artículo:", error);
    }
  };

  // Add item to sale
  const handleAddProduct = () => {
    if (itemResult && quantity > 0 && quantity <= itemResult.stock) {
      const updatedProducts = [...newSale.products];
      const existingProductIndex = updatedProducts.findIndex(
        (product) => product.id === itemResult.id
      );

      if (existingProductIndex !== -1) {
        // Increment quantity if the product already exists
        updatedProducts[existingProductIndex].quantity += quantity;
      } else {
        // Add new product
        updatedProducts.push({
          id: itemResult.id,
          image: itemResult.image,
          description: itemResult.description,
          ourPrice: itemResult.ourPrice,
          quantity,
        });
      }

      setNewSale((prev) => ({
        ...prev,
        products: updatedProducts,
        total: prev.total + itemResult.ourPrice * quantity,
      }));

      setItemResult(null);
      setSku("");
    }
  };
  // Remove product from sale
  const handleRemoveProduct = (productId) => {
    const updatedProducts = [...newSale.products];
    const productIndex = updatedProducts.findIndex((product) => product.id === productId);

    if (productIndex !== -1) {
      const product = updatedProducts[productIndex];
      const newQuantity = product.quantity - 1;

      if (newQuantity > 0) {
        updatedProducts[productIndex].quantity = newQuantity;
      } else {
        updatedProducts.splice(productIndex, 1);
      }

      setNewSale((prev) => ({
        ...prev,
        products: updatedProducts,
        total: prev.total - product.ourPrice,
      }));
    }
  };

  // Handle form submission
  const handleSaveSale = async () => {
    const { customer, paymentMethod, products, total } = newSale;

    if (!customer || !paymentMethod || products.length === 0) {
      alert("Por favor completa todos los campos.");
      return;
    }

    try {
      const batchUpdates = [];
      const saleRef = collection(db, "sales");

      // Add sale to Firestore
      const saleDoc = await addDoc(saleRef, {
        customer,
        paymentMethod,
        products: products.map((product) => ({
          id: product.id,
          quantity: product.quantity,
        })),
        total,
        date: new Date(),
      });

      // Update stock for each product
      for (const product of products) {
        const itemRef = doc(db, "items", product.id);
        const itemDoc = await getDoc(itemRef);

        if (itemDoc.exists()) {
          const currentStock = itemDoc.data().stock;
          const newStock = currentStock - product.quantity;

          batchUpdates.push(updateDoc(itemRef, { stock: newStock }));
        }
      }

      await Promise.all(batchUpdates);

      // Update sales list dynamically
      setSales((prev) => [
        ...prev,
        { id: saleDoc.id, customer, paymentMethod, total, date: new Date() },
      ]);

      alert("Venta registrada con éxito.");
      setNewSale({ customer: "", paymentMethod: "", products: [], total: 0 });
      setModalOpen(false); // Close modal
    } catch (error) {
      console.error("Error registrando venta:", error);
      alert("Error registrando la venta.");
    }
  };

  return (
    <div className="sales-container">
      <Header sectionName="Ventas" onLogout={logout} />
      <h1>Ventas</h1>

      {/* Lista de ventas */}
      <div className="sales-list">
        <h2>Lista de Ventas</h2>
        <ul>
          {sales.map((sale) => (
            <li key={sale.id}>
              <p>
                <strong>Cliente:</strong> {sale.customer}
              </p>
              <p>
                <strong>Fecha:</strong>{" "}
                {sale.date instanceof Date
                  ? sale.date.toLocaleDateString()
                  : new Date(sale.date.seconds * 1000).toLocaleDateString()}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {/* Botón para abrir el modal */}
      <button onClick={() => setModalOpen(true)} className="add-sale-button">
        Registrar Nueva Venta
      </button>

      {/* Modal */}
      {modalOpen && (
        <AddSaleModal
          newSale={newSale}
          sku={sku}
          itemResult={itemResult}
          quantity={quantity}
          message={message}
          setNewSale={setNewSale}
          setSku={setSku}
          setQuantity={setQuantity}
          setModalOpen={setModalOpen}
          handleSearchSKU={handleSearchSKU}
          handleAddProduct={handleAddProduct}
          handleRemoveProduct={handleRemoveProduct}
          handleSaveSale={handleSaveSale}
        />
      )}
    </div>
  );
};

export default Sales;
