export default class Item {
    /**
     * Constructor para un objeto Item.
     * @param {string} sku - El identificador único del ítem (document ID).
     * @param {string} image - URL de la imagen del ítem.
     * @param {string} description - Descripción del ítem.
     * @param {string} style - Descripción del ítem.
     * @param {number} originalPrice - Precio original del ítem.
     * @param {number} ourPrice - Precio de venta del ítem.
     * @param {number} stock - Cantidad de stock disponible.
     */
    constructor({ sku, image, description, style, originalPrice, ourPrice, stock }) {
      this.sku = sku; // ID único del documento
      this.image = image || "";
      this.description = description || "";
      this.style = style || "";
      this.originalPrice = originalPrice || 0;
      this.ourPrice = ourPrice || 0;
      this.stock = stock || 0;
    }
  
    /**
     * Crea un objeto Item a partir de un documento Firestore.
     * @param {string} id - ID del documento Firestore.
     * @param {Object} data - Datos del documento Firestore.
     * @returns {Item} Instancia de Item.
     */
    static fromFirestore(id, data) {
      return new Item({
        sku: id,
        image: data.image,
        description: data.description,
        style: data.style,
        originalPrice: data.originalPrice,
        ourPrice: data.ourPrice,
        stock: data.stock,
      });
    }
  
    /**
     * Convierte un objeto Item a un formato que Firestore puede guardar.
     * @returns {Object} Representación plana del Item.
     */
    static toFirestore() {
      return {
        image: this.image,
        description: this.description,
        style: this.style,
        originalPrice: this.originalPrice,
        ourPrice: this.ourPrice,
        stock: this.stock,
      };
    }
  }
  