import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDjyN7FnTF3TXuHdulySD3uZPFe_EpYkHQ",
  authDomain: "brillare-57a6e.firebaseapp.com",
  projectId: "brillare-57a6e",
  storageBucket: "brillare-57a6e.firebasestorage.app",
  messagingSenderId: "626409095294",
  appId: "1:626409095294:web:4eb054dc6f7b971090582f",
  measurementId: "G-6TJXB3KYH9"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);