import React from "react";

const AddSaleModal = ({
  newSale,
  sku,
  itemResult,
  quantity,
  message,
  setNewSale,
  setSku,
  setQuantity,
  setModalOpen,
  handleSearchSKU,
  handleAddProduct,
  handleRemoveProduct,
  handleSaveSale,
}) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Registrar Nueva Venta</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <label>
            Cliente:
            <input
              type="text"
              value={newSale.customer}
              onChange={(e) =>
                setNewSale({ ...newSale, customer: e.target.value })
              }
            />
          </label>
          <label>
            Método de Pago:
            <select
              value={newSale.paymentMethod}
              onChange={(e) =>
                setNewSale({ ...newSale, paymentMethod: e.target.value })
              }
            >
              <option value="">Selecciona</option>
              <option value="efectivo">Efectivo</option>
              <option value="tarjeta">Tarjeta</option>
              <option value="transferencia">Transferencia</option>
            </select>
          </label>
          <div className="product-search">
            <label>
              Buscar SKU:
              <input
                type="text"
                value={sku}
                onChange={(e) => setSku(e.target.value)}
              />
              <button type="button" onClick={handleSearchSKU}>
                Buscar
              </button>
            </label>
            {itemResult && (
              <div className="item-result">
                <img src={itemResult.image} alt={itemResult.description} />
                <p>
                  <strong>Producto:</strong> {itemResult.description}
                </p>
                <p>
                  <strong>Precio:</strong> ${itemResult.ourPrice}
                </p>
                <p>
                  <strong>Stock:</strong> {itemResult.stock}
                </p>
                {itemResult.stock > 0 ? (
                  <>
                    <input
                      type="number"
                      min="1"
                      max={itemResult.stock}
                      value={quantity}
                      onChange={(e) => setQuantity(Number(e.target.value))}
                    />
                    <button type="button" onClick={handleAddProduct}>
                      Agregar Producto
                    </button>
                  </>
                ) : (
                  <p className="error-message">
                    El producto no está disponible para entrega inmediata
                  </p>
                )}
              </div>
            )}
            {message && <p className="error-message">{message}</p>}
          </div>
          <div className="sale-products-list">
            <h3>Productos en la Venta:</h3>
            <div className="products-container">
              {newSale.products.map((product) => (
                <div key={product.id} className="product-thumbnail">
                  <img src={product.image} alt={product.description} />
                  <button
                    className="remove-product-button"
                    onClick={() => handleRemoveProduct(product.id)}
                  >
                    ✖
                  </button>
                  <span className="product-quantity">{product.quantity}</span>
                </div>
              ))}
            </div>
          </div>
          <p>
            <strong>Total:</strong> ${newSale.total.toFixed(2)}
          </p>
          <button type="button" onClick={handleSaveSale}>
            Guardar Venta
          </button>
          <button
            type="button"
            className="close-modal-button"
            onClick={() => setModalOpen(false)}
          >
            Cancelar
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddSaleModal;
