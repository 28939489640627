import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Header from "./Header";
import "./Home.css";

const Home = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="home">
      <Header sectionName="Administrador Brillare" onLogout={logout} />
      <div className="home-body">
        <div className="sidebar">
          <h2>Menú</h2>
          <button onClick={() => navigate("/inventory")}>Inventario</button>
          <button onClick={() => navigate("/orders")}>Ordenes</button>
          <button onClick={() => navigate("/sales")}>Ventas</button>
        </div>
        <div className="main-content">
          <h2>Bienvenido</h2>
          <p>Selecciona una opción del menú para comenzar.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
