import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, doc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import Item from "../models/Item";
import AddEditItemModal from "./AddEditItemModal";
import Header from "./Header";
import "./Inventory.css";

const Inventory = () => {
  const { logout } = useAuth();
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Obtener los items desde Firestore
  const fetchItems = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "items"));
      const itemsList = querySnapshot.docs.map((doc) =>
        Item.fromFirestore(doc.id, doc.data()) // Usamos la clase Item para estructurar los datos
      );
      setItems(itemsList);
    } catch (error) {
      console.error("Error al obtener los items:", error);
    }
  };

  // Guardar o actualizar un item en Firestore
  const saveItem = async (item) => {
    try {
      const itemRef = doc(db, "items", item.sku);
      await setDoc(itemRef, item); // Usamos el método toFirestore de la clase Item
      fetchItems(); // Actualizamos la lista
      closeModal();
    } catch (error) {
      console.error("Error al guardar el item:", error);
    }
  };

  // Eliminar un item
  const deleteItem = async (sku) => {
    try {
      await deleteDoc(doc(db, "items", sku));
      fetchItems(); // Actualizamos la lista
    } catch (error) {
      console.error("Error al eliminar el item:", error);
    }
  };

  // Manejar la apertura del modal para agregar o editar
  const openModal = (item = null) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  // Manejar el cierre del modal
  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  // Obtener los items al cargar el componente
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className="inventory">
      <Header sectionName="Inventario" onLogout={logout} />
      <button onClick={() => openModal()}>Agregar nuevo ítem</button>
      <ul className="inventory-list">
        {items.map((item) => (
          <li key={item.sku} className="inventory-item">
            {(!item.image || item.image.trim() === "") && (
              <div className="ribbon-circle-top-right">Sin imagen</div>
            )}
            {item.ourPrice === 0 && (
              <div className="ribbon-circle-bottom-left">Sin precio</div>
            )}
            <img src={item.image} alt={item.description} className="item-image" />
            <div className="item-details">
              <p><strong>SKU:</strong> {item.sku}</p>
              <p><strong>Estilo:</strong> {item.style}</p>
              <p><strong>Descripción:</strong> {item.description}</p>
              <p><strong>Precio original:</strong> ${item.originalPrice.toFixed(2)}</p>
              <p><strong>Precio de venta:</strong> ${item.ourPrice.toFixed(2)}</p>
              <p><strong>Stock:</strong> {item.stock}</p>
            </div>
            <button onClick={() => openModal(item)}>Editar</button>
            <button onClick={() => deleteItem(item.sku)}>Eliminar</button>
          </li>
        ))}
      </ul>

      {isModalOpen && (
        <AddEditItemModal
          item={selectedItem}
          onSave={saveItem}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default Inventory;
